:root {
  --dark-purple: #514b7e;
  --purple: #746ac0;
  --green: #69dca3;
  --star: #f8d64e;
  --button-text: #f7270f;
  --review-text: #9c9c9c;

  --background: #fff;
  --hamburger-color: #000;
  --text: var(--dark-purple);
  --shadow: 0 0.7rem 1rem rgba(0, 0, 0, 0.16);
  --episode-meta: #062043;
  --review-title: #000;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #111;
    --hamburger-color: #fff;
    --text: var(--purple);
    --shadow: none;
    --episode-meta: rgba(255, 255, 255, 0.6);
    --review-title: #fff;
  }
}

html {
  box-sizing: border-box;
  font-size: 10px;
}

* {
  margin: 0;
  box-sizing: inherit;
}

body {
  font-family: Roboto, sans-serif;
  background: var(--background);
}

::selection {
  background-color: var(--purple);
  color: white;
}
